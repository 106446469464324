<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
<style>
@font-face {
    font-family: 'DeliCustom';
    src: url('/src/assets/Delightious.otf');;
}

/* url('/src/assets/box.png'); */
</style>