import { createRouter, createWebHistory } from 'vue-router'

import Vue from 'vue';
import Router from 'vue-router';
import Verify from '../views/EmailVerify.vue'
import PrePage from '../views/Preregistro.vue'
import Home from '../views/PseudoHome.vue'
import reset from '../views/ResetPassword.vue'
import Support from '../views/Support.vue'
import GetUser from '../views/GetUsername.vue'
import RequestReset from '../views/RequestReset.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Verify',
    name: 'Verify',
    component: Verify
  },
  {
    path: '/support',
    name: 'Support',
    component: Support
  },
  {
    path: '/resetPassword',
    name: 'reset',
    component: reset
  },
  {
    path: '/GetUser',
    name: 'GetUser',
    component: GetUser
  },
  {
    path: '/RequestReset',
    name: 'RequestReset',
    component: RequestReset
  }
  ,
  {
    path: '/SignUp',
    name: 'SignUp',
    component: PrePage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
