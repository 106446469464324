<template>
  <v-parallax src='../assets/background.png' class="principalClass">
    <v-container>

      <!-- <v-toolbar allign="center" justify="center">
        
        <v-img :width="100" aspect-ratio="1/1" src="D:\BackupJun24\PSI-Logo.png"></v-img>
        <v-toolbar-title>Title</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text>Home</v-btn>
          <v-btn text>About</v-btn>
        </v-toolbar-items>
      </v-toolbar> -->
     




    </v-container>
    
  </v-parallax>
</template>

<script>

import axios from 'axios';
//import Form from './Form.vue';
export default {
  name: "App",
  data() {
    return {
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      sidebar: false,
      menuItems: [
        { title: 'Home', path: '/home', icon: 'mdi-home' },

      ]
    }
  },
  methods: {
    async fetchData() {

      const apiClient = axios.create({
        baseURL: 'http://93.127.197.124:3100/api',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      try {
        const response = await apiClient.get('/data');
        //this.data = response.data.message;
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    redirectToPage() {
      this.$router.push('/Form');
    },
    async VerifyMail(dataUrl) {

      const apiClient = axios.create({
        baseURL: 'http://93.127.197.124:3100/api',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      try {

        const dataSend = { user: dataUrl };  // Los datos que quieres enviar

        const response = await apiClient.post('/VerifyMail', dataSend);
        //this.data = response.data.message;
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {

   
  }
};
</script>
<style scoped>

.footer {
  color: black !important;
}

.iconSocials {
  max-width: 80px;
  max-height: 80px;
}

.image-row {
  margin-top: 10%;
  /* width: 100% !important; */
  background-color: transparent;

}

.principalClass {
  /* max-height: 1900px; */
}

.squareCard {
  background-image: url('/src/assets/box.png');
  background-size: cover;
  background-position: center;

  /* background-image: url("https://www.ausa.com.mx/img/bg-whatsapp.png"); */
}

.bk {
  /* background-color: #bff4ff !important; */
  /* Cambia este color a tu preferencia */
}

.sectionInfo {
  font-family: 'DeliCustom', sans-serif;
  font-size: 25px;
  min-height: 100% !important;
  height: 40vh !important;
  border-width: 0px;
  width: 100%;
  justify-content: center !important;
  color: white;

}

.chatSectionEmpty {
  font-family: 'DeliCustom', sans-serif;
  margin-bottom: -100px;
  font-size: 40px;
  min-height: 100% !important;
  height: 60vh;
  border-width: 0px;
  width: 100%;
  min-height: 40vh;
  justify-content: center !important;
  margin-top: 0%;
}

.relative-container {
  position: relative;
  width: 650px;
  height: 487.5px;
}

.background-image,
.overlay-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.background-image {
  max-height: 650px;
  max-width: 650px;
}

.letter {
  top: 45% !important;
  max-height: 350px;
  max-width: 350px;
}

.mt-custom {
  margin-top: 5% !important;
}

.flowersWhite {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  /* transform: translate(-50%, -50%) !important; */

}

.flowers {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  /* transform: translate(-50%, -50%) !important; */
}

.overlay-image:nth-child(2) {
  z-index: 2;
}

.overlay-image:nth-child(3) {
  z-index: 3;
}

.overlay-image:nth-child(4) {
  z-index: 4;
}



@media only screen and (max-height: 800px) and (max-width: 600px) {

  .chatSectionEmpty {

    margin-bottom: -100px;
    margin-top: 0%;
    height: 40vh !important;

  }

  .image-row {
    margin-top: 45% !important;
    /* width: 100% !important; */
    background-color: transparent;

  }

  .background-image {
    max-height: 350px;
    max-width: 350px;
  }

  .letter {
    top: 45% !important;
    max-height: 250px;
    max-width: 250px;
  }

  .flowersWhite {
    max-height: 370px;
    max-width: 370px;

  }

  .flowers {
    max-height: 370px;
    max-width: 370px;
  }

}

@media only screen and (max-height: 800px) {

  .chatSectionEmpty {

    margin-bottom: -100px;
    margin-top: 0%;
    height: 40vh !important;

  }

  .image-row {
    margin-top: 30%;
    /* width: 100% !important; */
    background-color: transparent;

  }

  .principalClass {
    min-height: 101%;
  }



}

@media only screen and (max-width: 600px) and (min-height: 800px) {

  .background-image {
    max-height: 350px;
    max-width: 350px;
  }

  .letter {
    top: 45% !important;
    max-height: 250px;
    max-width: 250px;
  }

  .mt-custom {
    margin-top: 6% !important;
  }

  .iconSocials {
    max-width: 60px;
    max-height: 60px;
  }

  .chatSectionEmpty {

    margin-bottom: -100px;
    margin-top: 0%;
    height: 40vh !important;

  }

  .image-row {
    margin-top: 10%;
    /* width: 100% !important; */
    background-color: transparent;

  }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 1600px) {

  .background-image {
    max-height: 650px;
    max-width: 650px;
  }

  .letter {
    top: 45% !important;
    max-height: 450px;
    max-width: 450px;
  }

  .flowersWhite {
    max-height: 370px;
    max-width: 370px;

  }

  .flowers {
    max-height: 370px;
    max-width: 370px;
  }

  .mt-custom {
    margin-top: 6% !important;
  }

  .iconSocials {
    max-width: 80px;
    max-height: 80px;
  }

  .chatSectionEmpty {

    margin-bottom: -100px;
    margin-top: 0%;
    height: 40vh !important;

  }

  .image-row {
    margin-top: 15%;
    /* width: 100% !important; */
    background-color: transparent;

  }

}


@media only screen and (min-width: 600px) and (max-width: 768px) and (min-height: 800px) {
  .background-image {
    max-height: 350px;
    max-width: 350px;
  }

  .letter {
    top: 45% !important;
    max-height: 200px;
    max-width: 200px;
  }

  .flowersWhite {
    max-height: 370px;
    max-width: 370px;

  }

  .flowers {
    max-height: 370px;
    max-width: 370px;
  }


  .mt-custom {
    margin-top: 6% !important;
  }

  .iconSocials {
    max-width: 60px;
    max-height: 60px;
  }

  .chatSectionEmpty {

    margin-bottom: -100px;
    margin-top: 0%;
    height: 60vh !important;

  }

  .image-row {
    margin-top: 10%;
    /* width: 100% !important; */
    background-color: transparent;

  }

  /* 
  .floating {
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 20px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.floatingWhite {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
} */
}
</style>
