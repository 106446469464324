<template>
  <v-parallax src='../assets/background.png' class="gralContainer">
    <v-container>

      <v-overlay :model-value="this.overlay" class="align-center justify-center">
        <v-progress-circular color="primary" size="64" indeterminate></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col>
          <v-img class="mx-auto" :width="300" aspect-ratio="1/1" src="../assets/PSI-Logo.png"></v-img>
        </v-col>
      </v-row>
      <v-container fluid class="position-relative">
        
        <!-- Select en la esquina superior derecha -->
        <v-select style="max-width: 200px; margin-top: -5%;" v-model="selectedLang" label="Language..." :items=langItems
          item-title="name" item-value="id" variant="solo" class="pa-1 top-right-select"></v-select>
        <v-spacer></v-spacer>
      </v-container>

      <v-container class="FormContainer">

        <v-dialog v-model="alert" max-width="600" persistent>

          <v-card prepend-icon="mdi-alert-circle" :title=titleText>
            <v-text class="mx-auto">
              {{ this.responseCodeTittle }}
            </v-text>
            </br>
            <!-- <v-text class="mx-auto">
              {{ this.responseCode }}
            </v-text>
            </br>
            <v-text class="mx-auto">
              {{ this.responseCodeAds }}
            </v-text> -->
            </br>
            <template v-slot:actions>

              <v-btn @click="closeDialog()">
                {{ this.selectedLang == 1 ? 'Agree' : 'Aceptar' }}
              </v-btn>
            </template>
          </v-card>
        </v-dialog>

        <!-- <v-row>
          <v-col v-if="this.selectedLang == 1">
            <v-img class="mx-auto" :width="500" aspect-ratio="1/1" src='../assets/pren.png'></v-img>
          </v-col>

          <v-col v-if="this.selectedLang != 1">
            <v-img class="mx-auto" :width="500" aspect-ratio="1/1" src='../assets/pres.png'></v-img>
          </v-col>
        </v-row> -->

        <v-row allign="center" justify="center">
          <v-col class="d-flex justify-center" xs="12" sm="12" md="6" lg="5">
            <v-card class="textForm">
              <v-card-text>
                <v-form ref="form">

                  <v-text-field v-model.trim="password" type="password" color counter :maxlength="50"
                    :rules="passwordRules" outlined :label="this.selectedLang == 1 ? 'Password' : 'Contraseña'"
                    required></v-text-field>
                  <v-text-field v-model.trim="confirmPassword" type="password" color counter :maxlength="50"
                    :rules="confirmPasswordRules" outlined
                    :label="this.selectedLang == 1 ? 'Confirm Password' : 'Confirma contraseña'"
                    required></v-text-field>

                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-row allign="center" justify="center">
                  <v-col style="max-width: 30%;">
                    <v-btn variant="tonal" style="border-radius: 25px;" class="mt-2" block @click="SendData">
                      {{ this.selectedLang == 1 ? 'Confirm' : 'Confirmar' }}
                    </v-btn>
                  </v-col>

                  <v-col style="max-width: 30%;">
                    <v-btn variant="tonal" style="border-radius: 25px;" class="mt-2" block @click="clean()">
                      {{ this.selectedLang == 1 ? 'Clear' : 'Limpiar' }}

                    </v-btn>
                  </v-col>

                </v-row>
              </v-card-actions>

            </v-card>
          </v-col>
        </v-row>

      </v-container>

    </v-container>

  </v-parallax>


</template>

<script>

import axios from 'axios';

export default {
  name: "App",
  data() {
    return {
      tokenVar: "",
      userVar: "",
      alert: false,
      overlay: false,
      responseCode: '',
      responseCodeTittle: '',
      responseCodeAds: '',
      selectedLang: 1,
      langItems: [
        {
          id: 1,
          name: 'English',
        },
        {
          id: 2,
          name: 'Español',
        }
      ],
      code: '',
      titleText: '',
      codeRules: [
        v => (v && v.length == 8 || v.length == 0) || (this.selectedLang == 1 ? 'Code must be 8 characters' : 'Codigo contener tener 8 caracteres'),
      ],
      termsRules: [
        v => !!v || (this.selectedLang == 1 ? 'This field is required' : 'Este campo es requerido'),
      ],
      poliRules: [
        v => !!v || (this.selectedLang == 1 ? 'This field is required' : 'Este campo es requerido'),
      ],
      name: '',
      nameRules: [
        v => !!v || 'User is required',
        v => (v && v.length > 7) || (this.selectedLang == 1 ? 'user must be more than 7 characters' : 'Usuario debe contener 7 o más caracteres'),
        v => /^[\w\s]+$/.test(v) || (this.selectedLang == 1 ? 'user must only contain alphabets, numeric' : 'Usuario no puede contener caracteres especiales'),
      ],
      password: '',
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length > 7) || (this.selectedLang == 1 ? 'Password must be more than 7 characters' : 'Password contener tener 7 o más caracteres'),
      ],
      confirmPassword: '',
      confirmPasswordRules: [
        v => !!v || 'Password is required',
        v => (this.password === this.confirmPassword) || (this.selectedLang == 1 ? 'Passwords must match' : 'Las contraseñas deben coincidir')],
      select: null,
     
      termsCheck: false,
      poliCheck: false,
      adsCheck: false,
      poliDialog: false,
      termsDialog: false,
      email: '',
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail is requred.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail must be valid.'
        },
      ],
      
    }
  },
  methods: {

    async validate() {
      const { valid } = await this.$refs.form.validate()

      if (valid) alert('Form is valid')
    },
    poliOpen() {
      this.poliDialog = true;
    },
    termsOpen() {
      this.termsDialog = true;
    },
    reset() {
      this.$refs.form.reset()
    },
    clean() {
      this.code = '';
      this.name = '';
      this.password = '';
      this.confirmPassword = '';
      this.email = '';
      this.code = '';
    },
    closeDialog() {

      if (this.titleText == 'Success' || this.titleText == 'Exíto') {
        location.reload();
      }

      this.alert = false;
    },
    resetValidation() {
      this.$refs.form.reset();
    },
    redirectToPage() {
      this.$router.push('/Form');
    },
    async SendData() {
      try {
        const { valid } = await this.$refs.form.validate()

        if (valid) {

          
          const apiClient = axios.create({
            baseURL: 'https://petparadisevirtualgame.com:8443/api',
            headers: {
              'Content-Type': 'application/json'
            }
          });

          try {

            this.VerifyPass(this.userVar,this.tokenVar,this.confirmPassword);

          } catch (error) {
            this.overlay = false;
          }


        }
        else {
        }




      } catch (error) {
      }
    },
    async VerifyPass(userEnc, tokenEnc,password) {

      const apiClient = axios.create({
        baseURL: 'https://petparadisevirtualgame.com:8443/api',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      try {

        const dataSend = { user: userEnc, token: tokenEnc, pass: password };  // Los datos que quieres enviar
        const response = await apiClient.post('/VerifyPasswordMail', dataSend);
        //this.data = response.data.message;
        var res = response.data;

        var objResponse = res.message;

        if(objResponse == 1){
          this.alert = true;
          this.titleText = this.selectedLang == 1 ? "Success" : 'Exíto';
          this.responseCodeTittle = this.selectedLang == 1 ? "The password has been changed successfully" : 'La contraseña ha sido cambiada con exíto';
        }
        else
        {
          this.alert = true;
          this.titleText = "Error";
          this.responseCodeTittle = this.selectedLang == 1 ? "An error has occurred" : 'Ha ocurrido un error';
        }
        
      } catch (error) 
      {
        console.error(error);
      }
    },

  },
  async mounted() {
    
    document.title = "Pet Society Island";
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    var userEncrypted;
    var tokenEncrypted;
    if (urlParams.has('token') && urlParams.has('user')) {
      // locationFilter = urlParams.get('user');
      
      userEncrypted = urlParams.get('user');
      this.userVar = userEncrypted.replace(' ', '+');

      tokenEncrypted = urlParams.get('token');
      this.tokenVar = tokenEncrypted.replace(' ', '+');

    

    }
    //var data = new URL(location.href).searchParams.get('data');
  
  }
};
</script>
<style scoped>
.footer {
  color: black !important;
}

.SquareCar {
  width: 50vh;
  height: 50vh;
}

.text-white input {
  color: black !important;

}

.sectionInfo {
  font-family: 'DeliCustom', sans-serif;
  /* Cambia el segundo valor por la fuente base si Delightious no está disponible */
  font-size: 25px;
  min-height: 100% !important;
  height: 40vh !important;
  border-width: 0px;
  width: 100%;
  justify-content: center !important;
  color: white;

}

.chatSectionEmpty {
  font-family: 'DeliCustom', sans-serif;
  margin-bottom: -100px;
  /* Cambia el segundo valor por la fuente base si Delightious no está disponible */
  font-size: 40px;
  min-height: 100% !important;
  height: 60vh !important;
  border-width: 0px;
  width: 100%;
  min-height: 40vh;
  justify-content: center !important;
}

.FormContainer {
  margin-top: 0%;
  /* justify-content: center !important; */
  /* max-width: 80%; */
  font-family: 'DeliCustom', sans-serif;
}

.gralContainer {
  height: -webkit-fill-available !important;
}

.textForm {
  width: inherit;
  border-radius: 3%;
}

.position-relative {
  position: relative;
}

.top-right-select {
  position: absolute;
  top: 16px;
  /* Ajusta según tus necesidades */
  right: 16px;
  /* Ajusta según tus necesidades */
}
</style>
