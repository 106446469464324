<template>
  <v-parallax src='../assets/background.png' class="principalClass">
    <v-container>

      <!-- <v-toolbar allign="center" justify="center">
        
        <v-img :width="100" aspect-ratio="1/1" src="D:\BackupJun24\PSI-Logo.png"></v-img>
        <v-toolbar-title>Title</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text>Home</v-btn>
          <v-btn text>About</v-btn>
        </v-toolbar-items>
      </v-toolbar> -->

      <v-row>
        <v-col>
          <v-img class="mx-auto" :width="250" aspect-ratio="1/1" src="../assets/PSI-Logo.png"></v-img>
        </v-col>
      </v-row>


      <v-container fluid class="chatSectionEmpty d-flex align-center justify-center">

        <v-container class="d-flex align-center justify-center" style="height: 100vh;">
          <v-row allign="center" justify="center">
            <v-col class="d-flex justify-center" xs="8" sm="8">
              <div class="relative-container">
                <v-img class="background-image" aspect-ratio="4/3" src='../assets/box.png'></v-img>
                <v-img class="overlay-image letter" style="margin-bottom: 10%;" aspect-ratio="4/3"
                  src='../assets/letter.png'></v-img>
                <v-img class="overlay-image flowersWhite floatingWhite" aspect-ratio="4/3"
                  src='../assets/flowers.png'></v-img>
                <v-img class="overlay-image flowers floating" aspect-ratio="4/3"
                  src='../assets/whiteFlowers.png'></v-img>
              </div>
            </v-col>
          </v-row>
        </v-container>

      </v-container>


      <v-container fluid class="sectionInfo bk">

        <!-- <v-row class="mt-custom mx-auto" allign="center" justify="center">
          <v-col class="d-flex justify-center">
            
            <v-img :max-height="380" :max-width="380" aspect-ratio="4/3" src='../assets/download.png'></v-img>
          </v-col>


        </v-row>
        <v-btn to="/Preregistration">Next Page</v-btn>
        <v-row class="mt-custom mx-auto" allign="center" justify="center">

          <v-col class="d-flex justify-center" xs="4" sm="4" md="2" lg="3">
            <v-img :max-height="200" :max-width="200" aspect-ratio="4/3" src='../assets/appstore.png'>
            </v-img>


          </v-col>

          <v-col class="d-flex justify-center" md="2" lg="3" xs="6" sm="6">
            <v-img :max-height="200" :max-width="200" aspect-ratio="4/3" src='../assets/google.png'>
            </v-img>


          </v-col>
        </v-row> -->

        <v-row class="mt-custom mx-auto" allign="center" justify="center">
          <v-col class="d-flex justify-center">

            <v-img :max-height="200" :max-width="700" aspect-ratio="4/3" src='../assets/yellowLine.png'>
            </v-img>
            <!-- <p class="text-center" style="position: absolute;">
              Follow us in our social networks!
            </p> -->
          </v-col>


        </v-row>


        <v-row class="mt-custom mx-auto" allign="center" justify="center">
          <v-col class="d-flex justify-center">

            <v-img :max-height="380" :max-width="380" aspect-ratio="4/3" src='../assets/follow.png'>
            </v-img>
            <!-- <p class="text-center" style="position: absolute;">
              Follow us in our social networks!
            </p> -->
          </v-col>


        </v-row>

        <v-row class="mt-custom mx-auto" allign="center" justify="center">

          <v-col class="d-flex justify-center" md="1" lg="2" xs="1" sm="2">

            <v-img @click="fbFunc()" class="iconSocials" aspect-ratio="4/3"
              href="https://www.facebook.com/PetSocietyIsland" target="_blank" src='../assets/fb.png'>
            </v-img>


          </v-col>
          
          <v-col class="d-flex justify-center" md="1" lg="2" xs="1" sm="2">
            <v-img @click="instFunc()" class="iconSocials" aspect-ratio="4/3" src='../assets/instagram.png'>
            </v-img>


          </v-col>


          <v-col class="d-flex justify-center" md="1" lg="2" xs="1" sm="2">
            <v-img @click="xFunc()" class="iconSocials" aspect-ratio="4/3" src='../assets/xlogo.png'>
            </v-img>


          </v-col>
        </v-row>




      </v-container>

      <!-- <v-card class="chatSectionEmpty">
    
  </v-card> -->
      <v-spacer></v-spacer>

      <!-- <div class="d-flex flex-column fill-height justify-center align-center text-white footer">
      <h1 class="text-h4 font-weight-thin mb-4">
        Footer
      </h1>
      <h4 class="subheading">
        Footer here Yair!
      </h4>
    </div> -->

    </v-container>
    <v-row class="image-row">
      <v-col class="justify-center">
        <v-img src='../assets/footer.png'>
        </v-img>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>

import axios from 'axios';
//import Form from './Form.vue';
export default {
  name: "App",
  data() {
    return {
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      sidebar: false,
      menuItems: [
        { title: 'Home', path: '/home', icon: 'mdi-home' },

      ]
    }
  },
  methods: {
    async fetchData() {

      const apiClient = axios.create({
        baseURL: 'http://93.127.197.124:3100/api',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      try {
        const response = await apiClient.get('/data');
        //this.data = response.data.message;
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    fbFunc() {
      window.open(
        'https://www.facebook.com/PetSocietyIsland',
        '_blank' // <- This is what makes it open in a new window.
      );
    },
    instFunc() {
      window.open(
        'https://www.instagram.com/PetSocietyIsland',
        '_blank' // <- This is what makes it open in a new window.
      );
    },
    xFunc() {
      window.open(
        'https://www.x.com/PSIslandGame',
        '_blank' // <- This is what makes it open in a new window.
      );
    },
    redirectToPage() {
      this.$router.push('/Form');
    },
    async VerifyMail(dataUrl) {

      console.log('Si tengo el USER');

      const apiClient = axios.create({
        baseURL: 'https://petparadisevirtualgame.com:8443/api',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      try {

        const dataSend = { user: dataUrl };  // Los datos que quieres enviar

        const response = await apiClient.post('/VerifyMail', dataSend);
        //this.data = response.data.message;
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {

    document.title = "Pet Society Island";
    //KdsWxWPOMayucem2oriF0w==
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    var paramEncrypted;
    if (urlParams.has('user')) {
      // locationFilter = urlParams.get('user');
      console.log(urlParams.get('user'));
      paramEncrypted = urlParams.get('user');

      paramEncrypted = paramEncrypted.replace(' ', '+');
      console.log('Si tengo el USER');
      this.VerifyMail(paramEncrypted);
    }
    //var data = new URL(location.href).searchParams.get('data');
    console.log(urlParams);
    //this.fetchData();
    //this.getReportInfo();


    // let data = await axios.get('http://localhost:5123/PetContoller/VerifyMail?dataurl=3fdfdf').then(res => res.data);
    // console.log(data);
    // axios.get('http://localhost:5123/PetContoller/VerifyMail?dataurl=3fdfdf')
    //   .then(response => {
    //     console.log('En el bueno:');
    //     console.log(response);
    //   })
    //   .catch(error => {
    //     console.log('En el error:');
    //     console.error('Error:', error);
    //   });

  }
};
</script>
<style scoped>

.footer {
  color: black !important;
}

.iconSocials {
  max-width: 80px;
  max-height: 80px;
}

.image-row {
  margin-top: 30%;
  /* width: 100% !important; */
  background-color: transparent;

}

.principalClass {
  /* max-height: 1900px; */
}

.squareCard {
  background-image: url('/src/assets/box.png');
  background-size: cover;
  background-position: center;

  /* background-image: url("https://www.ausa.com.mx/img/bg-whatsapp.png"); */
}

.bk {
  /* background-color: #bff4ff !important; */
  /* Cambia este color a tu preferencia */
}

.sectionInfo {
  font-family: 'DeliCustom', sans-serif;
  font-size: 25px;
  min-height: 100% !important;
  height: 40vh !important;
  border-width: 0px;
  width: 100%;
  justify-content: center !important;
  color: white;

}

.chatSectionEmpty {
  font-family: 'DeliCustom', sans-serif;
  margin-bottom: -100px;
  font-size: 40px;
  min-height: 100% !important;
  height: 60vh;
  border-width: 0px;
  width: 100%;
  min-height: 40vh;
  justify-content: center !important;
  margin-top: 0%;
}

.relative-container {
  position: relative;
  width: 650px;
  height: 487.5px;
}

.background-image,
.overlay-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.background-image {
  max-height: 650px;
  max-width: 650px;
}

.letter {
  top: 45% !important;
  max-height: 350px;
  max-width: 350px;
}

.mt-custom {
  margin-top: 5% !important;
}

.flowersWhite {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  /* transform: translate(-50%, -50%) !important; */

}

.flowers {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  /* transform: translate(-50%, -50%) !important; */
}

.overlay-image:nth-child(2) {
  z-index: 2;
}

.overlay-image:nth-child(3) {
  z-index: 3;
}

.overlay-image:nth-child(4) {
  z-index: 4;
}



@media only screen and (max-height: 800px) and (max-width: 600px) {

  .chatSectionEmpty {

    margin-bottom: -100px;
    margin-top: 0%;
    height: 40vh !important;

  }

  .image-row {
    margin-top: 45% !important;
    /* width: 100% !important; */
    background-color: transparent;

  }

  .background-image {
    max-height: 350px;
    max-width: 350px;
  }

  .letter {
    top: 45% !important;
    max-height: 250px;
    max-width: 250px;
  }

  .flowersWhite {
    max-height: 370px;
    max-width: 370px;

  }

  .flowers {
    max-height: 370px;
    max-width: 370px;
  }

}

@media only screen and (max-height: 800px) {

  .chatSectionEmpty {

    margin-bottom: -100px;
    margin-top: 0%;
    height: 40vh !important;

  }

  .image-row {
    margin-top: 30%;
    /* width: 100% !important; */
    background-color: transparent;

  }

  .principalClass {
    min-height: 101%;
  }



}

@media only screen and (max-width: 600px) and (min-height: 800px) {

  .background-image {
    max-height: 350px;
    max-width: 350px;
  }

  .letter {
    top: 45% !important;
    max-height: 250px;
    max-width: 250px;
  }

  .mt-custom {
    margin-top: 6% !important;
  }

  .iconSocials {
    max-width: 60px;
    max-height: 60px;
  }

  .chatSectionEmpty {

    margin-bottom: -100px;
    margin-top: 0%;
    height: 40vh !important;

  }

  .image-row {
    margin-top: 10%;
    /* width: 100% !important; */
    background-color: transparent;

  }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 2000px) {

  .background-image {
    max-height: 650px;
    max-width: 650px;
  }

  .letter {
    top: 45% !important;
    max-height: 450px;
    max-width: 450px;
  }

  .flowersWhite {
    max-height: 370px;
    max-width: 370px;

  }

  .flowers {
    max-height: 370px;
    max-width: 370px;
  }

  .mt-custom {
    margin-top: 6% !important;
  }

  .iconSocials {
    max-width: 80px;
    max-height: 80px;
  }

  .chatSectionEmpty {

    margin-bottom: -100px;
    margin-top: 0%;
    height: 40vh !important;

  }

  .image-row {
    margin-top: 45%;
    /* width: 100% !important; */
    background-color: transparent;

  }

}


@media only screen and (min-width: 600px) and (max-width: 768px) and (min-height: 800px) {
  .background-image {
    max-height: 350px;
    max-width: 350px;
  }

  .letter {
    top: 45% !important;
    max-height: 200px;
    max-width: 200px;
  }

  .flowersWhite {
    max-height: 370px;
    max-width: 370px;

  }

  .flowers {
    max-height: 370px;
    max-width: 370px;
  }


  .mt-custom {
    margin-top: 6% !important;
  }

  .iconSocials {
    max-width: 60px;
    max-height: 60px;
  }

  .chatSectionEmpty {

    margin-bottom: -100px;
    margin-top: 0%;
    height: 60vh !important;

  }

  .image-row {
    margin-top: 10%;
    /* width: 100% !important; */
    background-color: transparent;

  }

  /* 
  .floating {
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 20px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.floatingWhite {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
} */
}
</style>
